import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";
import TopNavigation from '../Components/Nav/TopNavigation';
import { toast } from 'react-toastify';
import { Html5QrcodeScanner } from "html5-qrcode";
import { useSelector } from 'react-redux';
import scanProcessUpdate from './../assets/procglass.jpg';
import scanImageSuccess from './../assets/congratss.png';
import scanImageFail from './../assets/coffeesad.jpg';

function SCScan_screen() {
    const params                                                       = useParams();
    const {user}                                                       = useSelector((state) => state.auth);
    const [singleEvent, setSingleEvent]                                = useState([]);
    const [eventFound, setEventFound]                                  = useState(false);
    const [scanResult, setScanResult]                                  = useState();
    const [proccessing, setProcessing]                                 = useState(false);

    const [successImage, setSuccessImage]                              = useState(false);
    const [negativeImage, setNegativeImage]                            = useState(false);

  useEffect(() => {
      collectTheCurrentEvent();
  },[])

  useEffect(() => {
    if(eventFound){
      createScannerAction();
    }
  }, [eventFound])

  const collectTheCurrentEvent = async () => {
        try{

            const singlesRes = await axios.get(CONSTANTS.API_URL +"events/single/" + params.id);

        setSingleEvent(singlesRes.data);
        console.log(singlesRes.data);
        setEventFound(true);
        }catch(err){
            console.log(err);
        }
    }

    const createScannerAction = () => {

      const scanner = new Html5QrcodeScanner(
        'reader',
        {
          qrbox: {
            width: 250,
            height: 250
          },
          fps: 3,    
        }
      );
    
      scanner.render(success, error);
    
      async function success(result){
        scanner.clear();
  
          const dataContent = {
            "supcode" : result,
            'eventcode': singleEvent.event_code,
            "phonenumber": user.phonenumber,
            "votetype": "Web"
        }
        console.log(dataContent);
        setProcessing(true);
        setScanResult("Processing...");
        try{
            const resData = await axios.put(CONSTANTS.API_URL +"suppliers/vote/current/sponser/", dataContent);
          
              //console.log(resData);
              // console.log(resData.data);
              if(resData.data.modifiedCount > 0){
                  toast.success("Submission was successful.");
                  setProcessing(false);
                  setSuccessImage(true);
                  setScanResult("Congratulations");
              }else {
                  toast.error("Invalid digit code");
                  setProcessing(false);
                  setNegativeImage(true);
                  setScanResult("Sorry...");
              }    
        } catch(err){
            console.log(err);
              toast.error("Duplicate scan.");
                setProcessing(false);
                setNegativeImage(true);
                setScanResult("Sorry...");
        } 
     }


      function error(){
        console.log(error);
      }
    }


  return (
    <div className="main-in-vers">
       <TopNavigation titleScreen={"Scan QR Code"} backStatus={true} linkText={"/qrcode/" + params.id} />
       <div className="content-screen pd10">
          {
              scanResult ? 
                 <div className="text-center">
                     {
                      proccessing && (
                        <img src={scanProcessUpdate} className="load-image-proc" />
                      )
                     }

                     {
                       successImage && (
                        <img src={scanImageSuccess} className="success-image" />
                       )
                     }

                     {
                       negativeImage && (
                        <img src={scanImageFail} className="success-image" />
                       )
                     }

                 </div>
              :
              <div id="reader">
              </div>
            }
        </div>
    </div>
  )
}

export default SCScan_screen