import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './logauth.css'
import * as CONSTANTS from "../CONSTANTS";
import HeaderLogo from './../assets/tlc_logo.png';
import circleA from './../assets/circles_a.png';
import circleB from './../assets/circles_b.png';
import circleC from './../assets/circles_c.png';
import circleD from './../assets/circles_d.png';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Spinner from '../Components/Other/Spinner';


function ForgotPassword() {
    const [isLoading, setIsLoading]                         = useState(false);
   
    const phonenumberRef                                    = useRef();
    const [newPassword, setNewPassword]                     = useState("");



    const handleForgotPassword = async (e) => {
        e.preventDefault();
 
        setIsLoading(true);
        try{

            const userData = {
                "phonenumber": phonenumberRef.current.value
              }       

              const passwordReset = await axios.put(CONSTANTS.API_URL +"auth/passreset", userData, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
          
            toast.success(passwordReset.data.message  );
            if(passwordReset.status == 200){
                setNewPassword(passwordReset.data.new_pass);
            }
            setIsLoading(false);
        }catch(err){
            console.log(err);
            setIsLoading(false);
        }             
    }

    if (isLoading) {
        return  <Spinner />
    }

  return (
    <div className="logo-base flexlog">
        <div className="log-start">
            <div className="reg-header mgtop50">
                <img src={HeaderLogo} className="logo-one" alt="The Local Choice" />         
            </div>
            <div className="main-login-data">
               
                <div className="form-card ">
                    <div className="frm-area mgtop30">
                        <h4 className="title-login text-center">RESET</h4>
                            <form encType="multipart/form-data" onSubmit={handleForgotPassword}>
                            <div className="form-group frg">
                                <div className="text-form">Email: </div>
                                <input type="number" className="form-control ct-content matetrial-input wide100" ref={phonenumberRef}  placeholder="Enter Phone Number" required/>
                            </div>
                        
                            <div className="form-group mgtop20">
                                <button className="btn btn-mevent btn-full" disabled={isLoading}>RESET YOUR ACCOUNT</button>
                            </div>
                            <div className=" frg">
                            </div>
                            </form>

                        
                            <p className="mgtop20">                             
                                <Link to="/login"  className="link-log-text">Login</Link>
                            </p>
                            {
                                newPassword && (
                                    <p className="text-center">
                                        Please login, new password: {newPassword}
                                    </p>
                                )
                            }
                            <img className="dot dot1" src={circleA} />
                            <img className="dot dot2" src={circleB} />
                            <img className="dot dot3" src={circleC} />
                    </div>
                </div>
            
            </div>
        </div>       
    </div>
  )
}

export default ForgotPassword