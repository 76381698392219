import React, { useEffect,useState } from 'react'
import * as CONSTANTS from "./../../CONSTANTS";
import axios from 'axios';
import rsvpIcon from '../../assets/young-rsvp.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
function RSVPComponent() {

  const {user}                                          = useSelector((state) => state.auth);

  const [processing, setProcessing]                     = useState(false);
  const [rsvpOn, setRSVPon]                             = useState(false);
  const [message, setMessage]                           = useState("");

  useEffect(() => {
    collectRSVPData();
    
  },[])

  const collectRSVPData = async () => {
    //console.log("_______________");
    //console.log("RSVP");
    //console.log(user);
    if(user?.rsvp.status){
      console.log("Hit hard");
    }
  }


    return (
        <div className='scroll-container newsContainer'>
          {
            user?.rsvp.status ?
              <div className="rsvp-content">

              </div>
            :
              <div className="init-rsvp text-center">
                  <h3 className="thin">Please RSVP today.</h3>
                  <img src={rsvpIcon} className="logo-one" alt="Mevent Question TLC" />  
                  <Link className="btn btn-mevent btn-full mgbot10" to="/rsvp" >
                    RSVP NOW
                  </Link>
              </div>   
          }
        </div>
      )
}
    
export default RSVPComponent
    