import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";
import TopNavigation from '../Components/Nav/TopNavigation';
import { FaStar, FaThumbsUp } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import celebrateNow from './../assets/celebratenow.jpg';
import { toast } from 'react-toastify';

function SCQR_CodeScreen() {

    const params                                                       = useParams();
    const {user}                                                       = useSelector((state) => state.auth);
    const [singleEvent, setSingleEvent]                                = useState([]);
    const [supplierList, setSupplierList]                              = useState([]);

    const [isPopupOpen, setIsPopupOpen]                                = useState(false);
    const [selectedSupplier, setSelecteSupplier]                       = useState(null);
    const [countFound, setCountFound]                                  = useState(0);

    const [rating, setRating]                                          = useState(1); // Initial rating state
    const [freshRate, setFreshRate]                                    = useState(false);

    useEffect(() => {
        collectCurrentSponsors();
    },[])

    function phoneNumberExists(phoneNumber, userScans) {
        return userScans.some(scan => scan.phonenumber === phoneNumber);
    }

    const collectCurrentSponsors = async () => {
        
        try{

            const singlesRes = await axios.get(CONSTANTS.API_URL +"events/single/" + params.id);
            // console.log("||||||||||||||||| Event");
            //console.log(singlesRes.data);
            setSingleEvent(singlesRes.data);
            //console.log("Inside TradeShow");
            if(singleEvent){      
                /////////////////////////////////                
                const eventSupplier = {
                    "eventCode" : singlesRes.data.event_code
                }
                
                const sponsorRes = await axios.put(CONSTANTS.API_URL +"suppliers/get/sponsor/events", eventSupplier,{
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                  if(sponsorRes.data.length > 0){
                    setSupplierList(sponsorRes.data);
                    //console.log(sponsorRes.data);
                    //Loop check
                    const lengthSupplier = sponsorRes.data.length;
                  
                    let foundNumber = 0;
                    for (let i = 0; i <lengthSupplier; i++) {
                       var exists = phoneNumberExists(user.phonenumber, sponsorRes.data[i].userScans );
                  
                       if(exists){
                        foundNumber++;
                       }
                    }
                    setCountFound(foundNumber);
                  }
                  
                /////////////////////////////////
            }
        }catch(err){
            console.log(err);
        }
    }

    const togglePopup = (sponsor) => {
        setSelecteSupplier(sponsor);
        setIsPopupOpen(!isPopupOpen);

        if(isPopupOpen == false){
            //Rating
            //Check if rating is empty or not
            if(sponsor.userRatings.length > 0){
                const empcodeExists = sponsor.userRatings.some(rating => rating.phonenumber === user.phonenumber);
                if(empcodeExists){
                    const ratingVoteCount = sponsor.userRatings.find(rating => rating.phonenumber === user.phonenumber);
                    setRating(ratingVoteCount.votecount);
                    setFreshRate(false);
                }else {
                    setFreshRate(true);
                }
                
            }else {
                //This is a fresh rating
                setFreshRate(true);
            
            }
        }
    };
    
    const handleRatingClick = (selectedRating) => {
        setRating(selectedRating);
    };

    
    const executeRating = async () => {
        try{
            const newRating =  {
                "phonenumber" : user.phonenumber,
                "supplierid" : selectedSupplier._id,
                "rating" : rating,
                "freshrating" : freshRate
            }

            const postRating = await axios.post(CONSTANTS.API_URL +"suppliers/exhibitor/rating", newRating,{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
          
            if(postRating.status == 200){
                toast.success("Thank you for your rating.");
            }else {
                toast.error("We must have had an issue with your rating. Please try again later.");  
            }
        }catch(error){
            console.log(error);
            toast.error("A problem occured trying to submit your rating. Please try again later.");  
        }
    }
  return (
    <div className="main-in-vers">
         <TopNavigation titleScreen={"QR Code"} backStatus={true} linkText={"/event-details/" + params.id} />
       <div className="content-screen">
   
           {
            countFound < supplierList.length ? (
                    <div className="button-rows pd10">
                        <Link to={"/qrscan/" + params.id} className="btn btn-mevent btn-full">Scan QR Code</Link>
                        <Link to={"/code/"+ params.id} className="btn btn-mevent mgtop10 btn-full">Use Code</Link>
                    </div>
                )
                :
                   <div className="full-container">
                     {
                        countFound > 0 && (
                            <img src={celebrateNow} className="celebrate-full" />
                        )
                     }
                    </div>
           }
            
            {
            supplierList && (
                <div className="pd10">
                    {
                        supplierList.map((supp, index) => {
                            const exists = phoneNumberExists(user.phonenumber, supp.userScans);

                            return (<div className={"card card-list-item mgtop10 pd10 found-" + exists} key={index} onClick={() => togglePopup(supp)}>
                                      <div className="flexme">
                                         <div className="img-front"  style={{  
                                                backgroundImage: "url(" + supp.logo_image + ")",
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat'
                                                }}>
                                         </div>
                                         <div className="supvoter">                                    
                                            <h3>{supp.name}</h3>   
                                            <div className="thin txt-short">Tap to rate experience with exhibitor</div>                                      
                                         </div>
                                         <div className="icon-box-black pdtop10">
                                            <FaThumbsUp />
                                         </div>
                                      </div>                               
                                    </div>)
                        })
                    }
                </div>
            )
        }
       </div>   
       {isPopupOpen && selectedSupplier && (
        <div className="modal-shade popup">
          <div className="modal-content">
                <div className="modal-body">
                    <div className="section-item">
                        <div className="thin">
                            Logo
                        </div>
                        <div className="text-center">
                            <img className="large-logo" src={selectedSupplier.logo_image} />
                        </div>
                    </div>
                    <div className="section-item">
                        <div className="thin">Ratings</div>
                        <div className="rating-stream">
                            <p>Please rate this exhibitor</p>
                            <div>
                            {[...Array(5)].map((star, index) => {
                                const ratingValue = index + 1;
                                return (
                                <label key={index}>
                                    <input
                                    type="radio"
                                    name="rating"
                                    value={ratingValue}
                                    className="ophide"
                                    onClick={() => handleRatingClick(ratingValue)}
                                    />
                                    <FaStar
                                        className="star"
                                        color={ratingValue <= rating ? '#ffc107' : '#e4e5e9'}
                                        size={25}
                                    />
                                </label>
                                );
                            })}
                         
                            </div>   
                            <div className="text-center pdtop10">
                                <button className="btn btn-mevent" onClick={() => executeRating()}>
                                    Submit Rating
                                </button>    
                            </div>         
                        </div>
                    </div>                    
                </div>
                <div className="modal-footer">                
                <button onClick={() => setIsPopupOpen(false)} className="btn btn-mevent">Close</button>
            </div>
          </div>
        </div>
        )}   
    </div>
  )
}

export default SCQR_CodeScreen
