import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as CONSTANTS from "../CONSTANTS";
import { FaBars } from 'react-icons/fa'
import NewsComponent from '../Components/Other/News';
import EventsComponent from '../Components/Other/Events';
import RSVPComponent from '../Components/Other/RSVP';

function Home() {

    const navigate              = useNavigate();
    const {user}                = useSelector((state) => state.auth)


    useEffect(() => {
      
        if(!user){
            navigate("/login")
        }
    },[user, navigate])

  return (
    <div className="main-standard-version pd10">
       <div className="shade-pad">
          <div className="flexme">
            <div className="flex_30 pd20">
                <div className="settings-link mgtop10">
                   <Link to="/settings" className="link-dark">
                      <FaBars />
                   </Link>
                </div>
                <div className="prof mgtop20">
                    <img src={CONSTANTS.IMAGE_PROFILE} className="imge-profile" />
                </div>
            </div>
            <div className="flex_70 pd20">
              <div className="gray-text">
                <p className="sm-text">{CONSTANTS.VERSION}</p>
                <p className="orange-caps">HELLO {user?.name} {user?.surname}</p>
                <p className="txtSize2 thin">Welcome to the Local Choice Franchise App</p>
              </div>
            </div>
          </div>
       </div>
        
       <div className="shade-pad mgtop10">
        <RSVPComponent />
       </div>
       <div className="block-section">
        <h4 className="smTitle">News</h4>
        <NewsComponent />
       </div>
       <div className="block-section">
        <h4 className="smTitle">Events</h4>
        <EventsComponent />
       </div>
    </div>
  )
}

export default Home