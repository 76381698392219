import React, { useEffect, useState } from 'react'
import TopNavigation from '../Components/Nav/TopNavigation'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import scanImage from './../assets/scan.png';
import agendaImage from './../assets/event.png';
import floorPlan from './../assets/floorplan.png';
import sponsorImag from './../assets/sponsor.png';

function EventsDetails() {
  const {user}                                                      = useSelector((state) => state.auth);
  const params                                                      = useParams();

  const [singleEvent, setSingleEvent]                               = useState([]);
  const [bannerSlider, setBannerSlider]                             = useState([]);

  const settings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1 // Number of slides to scroll at a time
  };

  useEffect(() => {
      collectTheCurrentEvent();
  },[])

  const collectTheCurrentEvent = async () => {
      try{

          const singlesRes = await axios.get(CONSTANTS.API_URL +"events/single/" + params.id);
         
        setSingleEvent(singlesRes.data);
        
        collectTheBanners(singlesRes.data.event_code)
      }catch(err){
          console.log(err);
      }
  }

  const collectTheBanners = async (eventCode) => {
    try{
       
      const slidersRes = await axios.get(CONSTANTS.API_URL +"events/banners/list/" + eventCode);

      setBannerSlider(slidersRes.data);
    }catch(err){
      console.log(err);
    }    
  }


  return (
    <div className="main-in-vers">
      <TopNavigation titleScreen={"Home"} backStatus={false} linkText={""}/>
      <div className="content-screen">
        {
          singleEvent && (
            <div className="info-event pd10">
                <h1 className="special-gray">{singleEvent.name}</h1>
                <div className="flexme">
                   <div className="flex_50">
                    <strong>{user.name}</strong>
                   </div>
                   <div className="flex_50">
                    <div className="thin text-right">
                    {user.phonenumber}
                    </div>
                   </div>
                </div>
                <div className="slider-block">
                    {
                      bannerSlider.length > 0 ?
                        <div className="banner-slider">
                          <Slider {...settings}>
                            {bannerSlider.map((item, index) => (
                              <img key={index} src={item.banner_url} className="img-banner" alt={`Banner ${index}`} />
                            ))}
                          </Slider>
                         
                        </div>
                      :
                      <div className="text-center">
                         <h3>No Slides</h3>
                      </div>
                    }
                </div>
                <div className="mevent-scrolls">
                    <div className="side-horizontal-scroller">
                        <div className="mevent-item-card">
                          <div className="card-white">
                             <Link to={"/qrcode/"+ singleEvent._id}>
                              <img src={scanImage} className="logo-two" alt="The Local Choice" />  
                              </Link>
                          </div>
                          <div className="card-gray">
                            <Link to={"/qrcode/"+ singleEvent._id}>
                              <div className="ct-bottom">
                                  <div className="text-white">
                                    <div className="bold">QR Codes</div>  
                                    <div className="thin">View QR code</div>  
                                  </div>
                              </div>
                              </Link>
                          </div>
                        </div>
                        <div className="mevent-item-card">
                          <div className="card-white">
                            <Link to={"/event/agenda/"+ singleEvent._id}><img src={agendaImage} className="logo-two" alt="The Local Choice" /> </Link> 
                          </div>
                          <div className="card-gray">
                             <Link to={"/event/agenda/"+ singleEvent._id}>
                              <div className="ct-bottom">
                                  <div className="text-white">
                                    <div className="bold">Agenda</div>  
                                    <div className="thin">View our agenda</div>  
                                  </div>
                              </div>
                              </Link>
                          </div>
                        </div>
                        <div className="mevent-item-card">
                          <div className="card-white">
                            <Link to={"/tradeshow/"+ singleEvent._id}><img src={floorPlan} className="logo-two" alt="The Local Choice" /> </Link> 
                          </div>
                          <div className="card-gray">
                             <Link to={"/tradeshow/"+ singleEvent._id}>
                              <div className="ct-bottom">
                                  <div className="text-white">
                                    <div className="bold">Find Your Way</div>  
                                    <div className="thin">View event and tradeshow layouts</div>  
                                  </div>
                              </div>
                              </Link>
                          </div>
                        </div>
                        <div className="mevent-item-card">
                          <div className="card-white">
                            <Link to={"/supplier/"+ singleEvent._id}><img src={sponsorImag} className="logo-two" alt="The Local Choice" /> </Link> 
                          </div>
                          <div className="card-gray">
                             <Link to={"/supplier/"+ singleEvent._id}>
                              <div className="ct-bottom">
                                  <div className="text-white">
                                    <div className="bold">Exhibitor</div>  
                                    <div className="thin">View our exhibitors</div>  
                                  </div>
                              </div>
                              </Link>
                          </div>
                        </div>

                    </div>
                </div>
            </div>
          )
        
        }
      </div>
    </div>
  )
}

export default EventsDetails
