import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "./../../CONSTANTS";
import axios from 'axios';
import { Link } from 'react-router-dom';

function EventsComponent() {
  const [processing, setProcessing]                     = useState(false);
  const [eventsList, setEventsList]                 = useState([]);
  const [message, setMessage]                           = useState("");

  useEffect(() => {
    collectEventList()
  },[])

  const collectEventList = async () => {
    try{
      setProcessing(true);
      const eventsRes = await axios.put(CONSTANTS.API_URL +"events/assembly/conditions");
     
      console.log(eventsRes.data);
      setEventsList(eventsRes.data)
      if(eventsRes.data.length === 0){
        setMessage("No Events Found")
      }
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

    return (
        <div className='scroll-container newsContainer'>
          {
            processing && (
              <div className="pro-figure text-center">
                <img src={CONSTANTS.SMALL_PROCESSING} className="processing-figure" />
              </div>
            )
          }
          {
            eventsList.length > 0 && (
              <div className="side-horizontal-scroller">
                  {
                    eventsList.map((eventItem, index) => {
                      return (<div className="card item-card image-card" key={index} style={{  
                                  backgroundImage: "url(" + eventItem.img_url + ")",
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat'
                                }}>
                                  <Link to={"/event-details/" + eventItem._id} className="link-dark">
                                <div className="image-over-filled">
                                  
                                  <div className="sect-evebot" >
                                     <div className="ev-title">{eventItem.name}</div>
                                     <div className="thin">{eventItem.event_date}</div>
                                  </div>
                                </div>
                              </Link>
                          </div>)
                    })
                  }
              </div>
            )
          }

          {
            message && (
              <h4>{message}</h4>
            )
          }

        </div>
      )
}
    
export default EventsComponent
    