import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";
import TopNavigation from '../Components/Nav/TopNavigation';
import { FaStar, FaThumbsUp } from 'react-icons/fa'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function SCCODE_Screen() {

    const params                                                       = useParams();
    const {user}                                                       = useSelector((state) => state.auth);
    const [proccessing, setProcessing]                                 = useState(false);
    const [singleEvent, setSingleEvent]                                = useState([]);

    const enterCodeRef                                              = useRef();

  useEffect(() => {
        collectTheCurrentEvent();
    },[])

    const collectTheCurrentEvent = async () => {
       
        try{

            const singlesRes = await axios.get(CONSTANTS.API_URL +"events/single/" + params.id);
          setSingleEvent(singlesRes.data);
          //console.log(singlesRes.data);
         
        }catch(err){
           console.log(err);
           
        }
    }

    const submitEnteredCode = async () => {
        setProcessing(true);
        try{
           
            const digitNine = enterCodeRef.current.value;
                if(digitNine.length === 9){
                    const dataContent = {
                        "supcode" : digitNine,
                        'eventcode': singleEvent.event_code,
                        "phonenumber": user.phonenumber,
                        "votetype": "Web"
                    }
                    
                    console.log(dataContent);

                    const resData = await axios.put(CONSTANTS.API_URL +"suppliers/vote/current/sponser/", dataContent);

                    //console.log(resData);
                // console.log(resData.data);
                    if(resData.data.modifiedCount > 0){
                        toast.success("Submission was successful.");
                        enterCodeRef.current.value = "";
                    }else {
                        toast.error("Invalid digit code");
                    }
                    setProcessing(false);
                }else {
                    toast.error("Please enter the 9 digit code");
                    setProcessing(false);
                }

        }catch(err){
            //console.log(err);
            toast.error(err.response.data.error);
            setProcessing(false);
        }
       
    }
  return (
    <div className="main-in-vers">
       <TopNavigation titleScreen={"Code"} backStatus={true} linkText={"/qrcode/" + params.id} />
       <div className="content-screen pd10">
           <div className="shade-pad">
                <div className="full-image2"  style={{  
                    backgroundImage: "url(" + singleEvent.img_url + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                    }}>
                </div>
                <h3 className="orange-caps txtSize2 text-center"> {singleEvent.event_date}</h3>
           </div>
           <div className="pd20">
           <h2 className="text-center">Please enter the code below</h2>
           </div>
           <div className="shade-pad">
               <div className="button-rows pd10">
                   <div className="form-group frg mgbot10">
                        <input type="number" className="form-control ct-content matetrial-input wide100" ref={enterCodeRef} placeholder="Enter Code" />
                    </div>
                    <button className="btn btn-mevent btn-full" onClick={() => submitEnteredCode()}>SUBMIT CODE</button>
                    <Link to={"/qrcode/" + params.id} className="btn btn-mevent mgtop10 btn-full">Return to QR Code</Link>
                </div>
           </div>
         
           {
                proccessing && (
                    <div className="gif-div">
                        <img src={CONSTANTS.SMALL_PROCESSING} className="proccesing-image" />
                    </div>
                )
            }
       </div>
    </div>
  )
}

export default SCCODE_Screen