import React, { useEffect } from 'react'
import TopNavigation from '../Components/Nav/TopNavigation';
import { useSelector } from 'react-redux';

function RSVPScreen() {
  const {user}                = useSelector((state) => state.auth);

  return (
    <div className="main-in-vers">
      <TopNavigation titleScreen={"RSVP"}  backStatus={false} linkText={""}/>
      <div className="content-screen">
            <iframe src={"https://rsvp.mevent.co.za/" + user.phonenumber } className="full-page">
            <p>Your browser does not support iframes.</p>
      </iframe>
      </div>
    </div>
  )
}

export default RSVPScreen