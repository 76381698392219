import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css'
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import Start from "./Pages/Start";
import Register from "./Pages/Register";
import ForgotPassword from "./Pages/ForgotPassword";
import { ToastContainer } from "react-toastify";
import RSVPScreen from "./Pages/RSVPScreen";
import Settings from "./Pages/Settings";
import EventsDetails from "./Pages/EventsDetails";
import BlogScreen from "./Pages/BlogScreen";
import AgendaScreen from "./Pages/AgendaScreen";
import TradeShowScreen from "./Pages/TradeShowScreen";
import SupplierScreen from "./Pages/SupplierScreen";
import SCQR_CodeScreen from "./Pages/SCQR_CodeScreen";
import SCCODE_Screen from "./Pages/SCCODE_Screen";
import SCScan_screen from "./Pages/SCScan_screen";

function App() {
  return (
    <>
    <Router>
      <div className="main-container">
        <Routes>
           <Route path="/login" element={<Login />}/>  
           <Route path="/register" element={<Register />}/>  
           <Route path="/forgot-password" element={<ForgotPassword />}/>  
           <Route element={<Start />}>
               <Route path="/" element={<Home />}/> 
               <Route path="/home" element={<Home />}/> 
               <Route path="/rsvp" element={<RSVPScreen />}/> 
               <Route path="/settings" element={<Settings />}/> 
               <Route path="/event-details/:id" element={<EventsDetails />}/> 
               <Route path="/blog/:id" element={<BlogScreen/>}/> 
               <Route path="/event/agenda/:id" element={<AgendaScreen />}/> 
               <Route path="/tradeshow/:id" element={<TradeShowScreen />}/> 
               <Route path="/supplier/:id" element={<SupplierScreen />}/> 
               <Route path="/qrcode/:id" element={<SCQR_CodeScreen />}/> 
               <Route path="/code/:id" element={<SCCODE_Screen />}/> 
               <Route path="/qrscan/:id" element={<SCScan_screen />}/> 
           </Route>
        </Routes>
      </div>
    </Router>
    <ToastContainer />
    </>
  );
}

export default App;
