import React, { useEffect, useState } from 'react'
import TopNavigation from '../Components/Nav/TopNavigation'
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";
import { FaClock, FaExclamation } from 'react-icons/fa'

function AgendaScreen() {
    const {user}                                                      = useSelector((state) => state.auth);
    const params                                                      = useParams();

    const [singleEvent, setSingleEvent]                               = useState([]);
    const [agendaList, setAgendaList]                                 = useState([]);
    const [isPopupOpen, setIsPopupOpen]                               = useState(false);
    const [selectedAgenda, setSelectedAgenda]                         = useState(null);
    const [binderList, setBinderList]                                 = useState([]);

    useEffect(() => {
        collectCurrentAgenda();
    },[])

    const collectCurrentAgenda = async () => {
        
        try{

            const singlesRes = await axios.get(CONSTANTS.API_URL +"events/single/" + params.id);

            setSingleEvent(singlesRes.data);
            //console.log("Inside Agenda");
            if(singleEvent){
            
                /////////////////////////////////
                let currentDate = new Date();
                let day = String(currentDate.getDate());
                if (day.length === 1) {
                   day = "0" + day;
                }

                let firstDateTime = new Date(singlesRes.data.event_date);
                let lastDateTime = new Date(singlesRes.data.end_date);
                let dayIndex = 1;

                if (currentDate < firstDateTime) {
                   console.log(">>>> Today is before start date. " + dayIndex);
                } else {
                    if ((currentDate.getFullYear() === lastDateTime.getFullYear() &&
                        currentDate.getMonth() === lastDateTime.getMonth() &&
                        currentDate.getDate() === lastDateTime.getDate()) ||
                        currentDate < lastDateTime) {
                        let differenceInDays = Math.floor((currentDate - firstDateTime) / (1000 * 60 * 60 * 24));
                        dayIndex = differenceInDays + 1;
                    } else {
                        console.log("## Today has passed the event date. ##");
                        dayIndex = 0;
                    }
                }

                //console.log(dayIndex);

                const eventAgenda = {
                    "eventcode" : singlesRes.data.event_code,
                    "agendday" : dayIndex,
                }
            
                if(dayIndex > 0){

                 
                    const agendasRes = await axios.put(CONSTANTS.API_URL +"events/agenda/list/eventday", eventAgenda,{
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
               
                    if(agendasRes.data.length > 0);
                    setAgendaList(agendasRes.data);
                }
                
                /////////////////////////////////
            }
        }catch(err){
            console.log(err);
        }
    }

    const togglePopup = (agenda) => {
        setSelectedAgenda(agenda);
        setIsPopupOpen(!isPopupOpen);

        if(isPopupOpen == false){
            console.log(agenda);
            //check for binders
            checkForTheBinders(agenda._id);
        }
    };
    
    const checkForTheBinders = async (agendaId) => {
        try{
       
            const responseBinders = await axios.get(CONSTANTS.API_URL +"events/binders/list/" + agendaId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log(responseBinders);

            if(responseBinders.status == 201){
                setBinderList(responseBinders.data);
            }

        }catch(err){
            console.log(err);
        }
    }
  return (
    <div className="main-in-vers">
        <TopNavigation titleScreen={"Agenda"} backStatus={true} linkText={"/event-details/" + params.id} />
        <div className="content-screen">
           {
            agendaList && (
                <div className="pd10">
                    {
                        agendaList.map((agenda, index) => {
                            return <div className="card card-list-item mgtop10" key={index} onClick={() => togglePopup(agenda)}>
                                    <h3>{agenda.title}</h3>
                                    <div className="pd10 flexme">
                                        <div className="icon-box">
                                        <FaClock />
                                        </div>
                                        <div className="content-box-time">
                                            <div className="flexme fljust">
                                                <div className="">
                                                    <div className="thin">START</div>
                                                    <div className="bold ct-time">{agenda.start_time}</div>
                                                </div>
                                                <div className="">
                                                    <div className="thin">END</div>
                                                    <div className="bold ct-time">{agenda.end_time}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="icon-box">
                                            <FaExclamation />
                                        </div>
                                    </div>
                            </div>
                        })
                    }
                </div>
            )
           }
        </div>
        {isPopupOpen && selectedAgenda && (
        <div className="modal-shade popup">
          <div className="modal-content">
                <div className="modal-body">
                    <div className="section-item">
                        <div className="thin">Details</div>
                        <div className="text-center">
                            Title
                            { <p className="bold">{selectedAgenda.title}</p> }
                            Date
                            { <p className="bold">{selectedAgenda.event_date}</p> }
                            START
                            { <p className="bold">{selectedAgenda.start_time}</p> }
                            END
                            { <p className="bold">{selectedAgenda.end_time}</p> }                   
                        </div>
                    </div>
                    {
                       ( selectedAgenda.speakers.length > 0   ) ? 
                       <div className="section-item speakers-sect">
                            <div className="thin">Speakers</div>
                            <div className="side-horizontal-scroller">
                            {
                                    selectedAgenda.speakers.map((speakers, index) => {
                                    return (<div className="card item-card" key={index}>
                                        
                                       
                                        <div style={{  
                                                backgroundImage: "url(" + speakers.img_url + ")",
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat'
                                                }} className="full-image" ></div>
                                            <div className="bl-title">                                            
                                                <div className="bold">{speakers.name} {speakers.surname}</div>    
                                                <div className="orange-text thin">{speakers.jobtitle}</div>                                           
                                            </div>
                                            
                                            <div className="accordion-mev">
                                                 <input type="checkbox" id={`accordion-${index}`} className="accordion-input" />
                                                 <label htmlFor={`accordion-${index}`} className="accordion-label">
                                                    <span className="accordion-title">Bio</span>
                                                  </label>
                                                  <div className="accordion-content">
                                                    {speakers.bio}
                                                  </div>
                                             
                                            </div>
                                    </div>)
                                    })
                                }
                            </div>
                        </div>
                       : <div className="">
                           <h3 className="thin">No Speakers</h3>                       
                        </div>                     
                    }

                    {
                        binderList && (
                            <div className="section-item bind-sect mgtop10">
                                  <div className="thin">Download Files</div>    
                                <ul>
                                {
                                    binderList.map((binder, index) => {
                                       return <li key={index}>
                                         <Link to={binder.binder_url} target="_blank" download>
                                            {binder.name}
                                         </Link>
                                       </li>
                                    })
                                } 
                                </ul>
                            </div>
                        )
                    }
                </div>
                <div className="modal-footer">                
                <button onClick={() => setIsPopupOpen(false)} className="btn btn-mevent">Close</button>
            </div>
          </div>
        </div>
        )}
    </div>
  )
}

export default AgendaScreen
