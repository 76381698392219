import React, { useEffect, useState } from 'react'
import TopNavigation from '../Components/Nav/TopNavigation'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";

function TradeShowScreen() {
    const params                                                      = useParams();

    const [singleEvent, setSingleEvent]                                = useState([]);
    const [tradeShowList, setTradeShowList]                            = useState([]);

    useEffect(() => {
        collectCurrentTradeShow();
    },[])

    const collectCurrentTradeShow = async () => {
        
        try{

            const singlesRes = await axios.get(CONSTANTS.API_URL +"events/single/" + params.id);
            // console.log("||||||||||||||||| Event");
            //console.log(singlesRes.data);
            setSingleEvent(singlesRes.data);
            //console.log("Inside TradeShow");
            if(singleEvent){
                console.log("single Event");
                console.log(singleEvent);
                /////////////////////////////////
                const tradeRes = await axios.get(CONSTANTS.API_URL +"events/tradeshows/list/" + singlesRes.data.eventid);
                
                 if(tradeRes.data.length > 0){
                    setTradeShowList(tradeRes.data);
                 }
                /////////////////////////////////
            }
        }catch(err){
            console.log(err);
        }
    }
  return (
    <div className="main-in-vers">
       <TopNavigation titleScreen={"Find Your Way"} backStatus={true} linkText={"/event-details/" + params.id} />
       <div className="content-screen">
        {
            tradeShowList && (
                <div className="pd10">
                    {
                        tradeShowList.map((tradeWork, index) => {
                          return (<div className="card card-list-item mgtop10" key={index} >
                                        <Link to={tradeWork.pdf_url}>
                                        <h3>{tradeWork.name}</h3>
                                        </Link>                                 
                                </div>
                                )
                        })
                    }
                </div>
            )
        }
        </div>      
    </div>
  )
}

export default TradeShowScreen
