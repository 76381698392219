import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './logauth.css'
import * as CONSTANTS from "../CONSTANTS";
import HeaderLogo from './../assets/tlc_logo.png';
import circleA from './../assets/circles_a.png';
import circleB from './../assets/circles_b.png';
import circleC from './../assets/circles_c.png';
import circleD from './../assets/circles_d.png';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { register, reset } from '../reduxAuth/authSlice';
import Spinner from '../Components/Other/Spinner';


function Register() {
    const [erEmpty, setErEmpty]                         = useState("");
    const [erMClass, setErrMClass]                      = useState("");

    const emailRef                                      = useRef();
    const passwordRef                                   = useRef();
    const phonenumberRef                                = useRef();
    const idNumberRef                                   = useRef();
    const jobTitleRef                                   = useRef();
    const dietaryRef                                    = useRef();
    const personNameRef                                  = useRef();
    const personSurnameRef                               = useRef();

    const navigate                                       = useNavigate();
    const dispatch                                       = useDispatch();

    const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

    useEffect(() => {
        
        if(isError){
            toast.error(message)
        }
      
        
        if(isSuccess || user){
            toast.success("Thank you for registration, please login");
           // navigate('/');
        }
        
        dispatch(reset())
    },[user, isError, isSuccess, message, navigate, dispatch])

    const handleRegistration = async (e) => {
        e.preventDefault();
 
        let emailCheckTest = isValidEmail(emailRef.current.value);
        if(emailCheckTest){

            const userData = {
                "email": emailRef.current.value,
                "password": passwordRef.current.value,
                "phonenumber": phonenumberRef.current.value,
                "profession": jobTitleRef.current.value,
                "idnumber": idNumberRef.current.value,
                "dietary": dietaryRef.current.value,
                "surname":personSurnameRef.current.value,
                "name":personNameRef.current.value
              }        
              
              dispatch(register(userData));
         
        }else {
            toast.error("Email not valid");
        }
    }

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }

    if (isLoading) {
        return  <Spinner />
    }

  return (
    <div className="logo-base flexlog">
        <div className="log-start">
            <div className="reg-header">
                <img src={HeaderLogo} className="logo-one rg-tlc" alt="The Local Choice" />         
            </div>
            <div className="main-login-data">
               
                <div className="form-card ">
                    <div className="frm-area">
                        <h4 className="title-register text-center">Register</h4>
                            <form encType="multipart/form-data">
                            <div className="form-group frg">
                                <div className="text-form">Phone Number: </div>
                                <input type="number" className="form-control ct-content matetrial-input wide100" ref={phonenumberRef} placeholder="Phone Number" defaultValue={0} required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">ID Number: </div>
                                <input type="text" className="form-control ct-content matetrial-input wide100" ref={idNumberRef} placeholder="ID Number" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Email: </div>
                                <input type="email" className="form-control ct-content matetrial-input wide100" ref={emailRef}  placeholder="Enter Email Address" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Password: </div>
                                <input type="password" className="form-control ct-content matetrial-input wide100"  ref={passwordRef} placeholder="Enter Password" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Name: </div>
                                <input type="text" className="form-control ct-content matetrial-input wide100" ref={personNameRef}  placeholder="Enter Name" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Surname: </div>
                                <input type="text" className="form-control ct-content matetrial-input wide100" ref={personSurnameRef}  placeholder="Enter Surname" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Job Title: </div>
                                <input type="text" className="form-control ct-content matetrial-input wide100" ref={jobTitleRef}  placeholder="Enter Job tilte" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Dietary: </div>
                            
                                <select className="form-control ct-content matetrial-input wide100" ref={dietaryRef}>
                                    <option value="None">None</option>
                                    <option value="Vegetarian">Vegetarian</option>
                                    <option value="Kosher">Kosher</option>
                                    <option value="Halal">Halal</option>
                                </select>
                            </div>
                      
                            <div className="form-group mgtop20">
                                <button className="btn btn-mevent btn-full" onClick={handleRegistration} disabled={isLoading}>Sign Up</button>
                            </div>
                            <div className=" frg">
                            </div>
                            </form>

                            {
                                erEmpty && (
                                    <div className={"alert " + erMClass}>
                                        {erEmpty}
                                    </div>
                                )
                            }
                            
                            <p className="mgtop20">
                                <Link to="/login" className="link-log-text">Login</Link>
                            </p>

                            <img className="dot dot1" src={circleA} />
                            <img className="dot dot2" src={circleB} />
                            <img className="dot dot3" src={circleC} />
                    </div>
                </div>
            
            </div>
        </div>       
    </div>
  )
}

export default Register